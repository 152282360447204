import { useRef } from 'react'

import { Button } from './Button'

import { LinkType } from '../../generated/content'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'

import { useWindowSize } from 'react-use'
import style from './TileSlider.module.sass'

export const TileSlider = ({ link, sublink, tiles }: any) => {
	const { width } = useWindowSize()
	const containerRef = useRef<HTMLDivElement>(null)

	const scrollType = (type: 'prev' | 'next') => {
		if (containerRef.current) {
			const cardWidth = containerRef.current.firstElementChild?.clientWidth || 0

			if (cardWidth > 0) {
				containerRef.current.scrollBy({
					left: type === 'prev' ? -cardWidth : cardWidth,
					behavior: 'smooth',
				})
			}
		}
	}

	return (
		<section className={style.sectionWrapper}>
			<div style={{ position: 'relative' }} className={style.sectionContent}>
				<button onClick={() => scrollType('prev')} className={style.prevBtn}>
					<Chevron />
				</button>
				<button onClick={() => scrollType('next')} className={style.nextBtn}>
					<ChevronRight />
				</button>

				<div
					ref={containerRef}
					className={style.tilesContainer}
					style={{ scrollSnapType: 'x mandatory', borderRadius: `1.5rem` }}>
					{tiles.items.map((t: any, i: number) => (
						<div key={i} className={style.tileWrapper} style={{ scrollSnapAlign: 'start' }}>
							<img
								src={t.image.url}
								style={{
									width: '100%',
									height: 'auto',
									display: 'block',
								}}
								alt={t.image.alt || t.image.fileName}
							/>
							<div className={style.content}>
								<span className={style.desc}>{t.description}</span>
								<h6 className={style.heading}>{t.title}</h6>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className={style.buttonsContainer} hidden={!link || !sublink || (!link && !sublink)}>
				{link && (
					<Button
						type="link"
						href={contemberLinkToHref(link)}
						fill="blue"
						target={
							link.type !== LinkType.scrollToTarget && link.isTargetBlank ? '_blank' : undefined
						}>
						{link.title}
					</Button>
				)}
				{sublink && (
					<div hidden={width <= 600}>
						<Button
							outline={true}
							type="link"
							href={contemberLinkToHref(sublink)}
							fill="transparent"
							target={
								sublink.type !== LinkType.scrollToTarget && sublink.isTargetBlank
									? '_blank'
									: undefined
							}>
							{sublink.title}
						</Button>
					</div>
				)}
			</div>
		</section>
	)
}

const Chevron = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
			<path
				d="M21 26L11.2404 16.5385C11.1645 16.4694 11.104 16.386 11.0627 16.2934C11.0213 16.2008 11 16.1009 11 16C11 15.8991 11.0213 15.7992 11.0627 15.7066C11.104 15.614 11.1645 15.5306 11.2404 15.4615L21 6"
				stroke="black"
				stroke-width="2.4"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}

const ChevronRight = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
			<path
				d="M11 6L20.7596 15.4615C20.8355 15.5306 20.896 15.614 20.9373 15.7066C20.9787 15.7992 21 15.8991 21 16C21 16.1009 20.9787 16.2008 20.9373 16.2934C20.896 16.386 20.8355 16.4694 20.7596 16.5385L11 26"
				stroke="black"
				stroke-width="2.4"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	)
}
