import Link from 'next/link'
import styles from './ThankYouHome.module.sass'

export const ThankYouHome = () => {
	return (
		<div className={styles.rightSide}>
			<div className={styles.card}>
				<div className={styles.cardContentWrapper}>
					<div className={styles.cardTitleContainer}>
						<p className={styles.cardTitle}>
							Thank you for <br />
							reaching out!
						</p>
					</div>
					<p className={styles.cardDescription}>
						Check out some of the amazing content other companies are creating with Ceros to inspire
						your next project.
					</p>
					<Link className={styles.linkButton} href="https://www.ceros.com/inspire/">
						Get Inspired
					</Link>
				</div>
			</div>
		</div>
	)
}
