import Image from 'next/image'
import GetInspired from './../../public/images/GetInspired.png'
import SneakPeek from './../../public/images/SneakPeek.png'
import WhyCeros from './../../public/images/WhyCeros.webp'

import Link from 'next/link'
import styles from './ThankYouDemoRequest.module.sass'

export const ThankYouDemoRequest = () => {
	// const video = {
	// 	videoType: 'wistia',
	// 	poster: null,
	// 	posterMobile: null,
	// 	video: null,
	// 	videoMobile: null,
	// 	youtube: null,
	// 	wistiaUrl: 'https://ceros.wistia.com/medias/d2wazcfk30',
	// }

	// const [isModalOpen, setIsModalOpen] = useState(false)

	// const handleModalOpen = useCallback(() => {
	// 	setIsModalOpen(!isModalOpen)
	// }, [isModalOpen])

	return (
		<div className={styles.wrapper}>
			{/* <VideoModal isOpen={isModalOpen} onRequestClose={handleModalOpen} video={video as any} /> */}

			{/* left side */}
			<div className={styles.leftSideContainer}>
				<p className={styles.title}> We'll be in touch shortly!</p>
				<p className={styles.description}>
					We can’t wait to show you what’s possible. Sit tight—someone from our team will be in
					touch soon. Until then, check out our gallery of examples and templates powered by Ceros.
				</p>
			</div>
			{/* right side ( grid ) */}
			<div className={styles.gridContainer}>
				<div className={styles.firstRow}>
					<Link
						target="_blank"
						href="https://www.ceros.com/industries-hub/?utm_source=website&utm_medium=demo&utm_campaign=why_ceros"
						className={styles.box}>
						<Image src={WhyCeros} alt={'Why Ceros'} className={styles.image} />
						<p className={styles.boxTitle}>Why Ceros?</p>
					</Link>
					<Link
						target="_blank"
						href="https://www.ceros.com/inspire?utm_source=website&utm_medium=demo&utm_campaign=get_inspired"
						className={styles.box}>
						<Image src={GetInspired} alt={'Get Inspired'} className={styles.image} />
						<p className={styles.boxTitle}>Get Inspired</p>
					</Link>
				</div>
				<div className={styles.secondRow}>
					<Link
						className={styles.box}
						href={'https://www.youtube.com/watch?v=nxNaKEhDAuk'}
						target="_blank">
						<Image src={SneakPeek} alt={'Sneak Peek'} className={styles.image} />
						<p className={styles.boxTitle}> Product sneak peek</p>
					</Link>
				</div>
			</div>
		</div>
	)
}
