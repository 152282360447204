import clsx from 'clsx'

import Image from 'next/image'
import { useState, type FunctionComponent } from 'react'
import { BackgroundType } from '../../generated/content'
import type { FormResult } from '../data/content/FormFragment'
import { lazyBoundary } from '../images/configuration'
import { BreakableContent } from './BreakableContent'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Container } from './Container'
import styles from './DemoRequestHero.module.sass'
import { HubspotEmbedForm } from './HubspotEmbedForm'
import { ThankYouDemoRequest } from './ThankYouDemoRequest'
import { useTheme } from './ThemeProvider'
import { WaitlistForm } from './WaitlistForm'

export type DemoRequestHero = {
	title?: string
	form?: FormResult
	background?: BackgroundType
	isWaitList?: boolean
	descTile?: any
}

export const DemoRequestHero: FunctionComponent<any> = ({
	title,
	form,
	background,
	isWaitList,
	descTile,
}) => {
	const theme = useTheme()
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`is_theme_${theme.primary}`],
				styles[`is_background_${background}`]
			)}>
			<Container disableGutters>
				<div className={styles.main}>
					{title && (
						<BreakableContent breakableFrom="768">
							<h1 style={{ position: 'relative' }} className={styles.title}>
								<CerosRichTextRenderer source={title} />
							</h1>
						</BreakableContent>
					)}
				</div>

				{form?.hubspotFormId && (
					<div className={styles.formWrapper}>
						{isSubmitted ? (
							<ThankYouDemoRequest />
						) : (
							<div className={styles.leftWrapper}>
								<div className={styles.formLeftSide}>
									<div className={styles.formLeftSideContainer}>
										<div>
											<p className={styles.formLeftSideTitle}>Schedule a demo</p>
										</div>

										{descTile && (
											<div className={styles.tileWrapper}>
												{descTile?.items.map((t: any) => {
													return (
														<div className={styles.tileContainer}>
															{t.icon?.icon && (
																<Image
																	src={t.icon?.icon.url}
																	alt={t.icon.icon.alt || (t.icon.icon.fileName as string)}
																	key={t.icon.id}
																	width={18}
																	height={18}
																	style={{ marginTop: '5px' }}
																/>
															)}
															{t.description && <CerosRichTextRenderer source={t.description} />}
														</div>
													)
												})}
											</div>
										)}
									</div>
								</div>
								<div className={styles.form}>
									{isWaitList ? (
										<WaitlistForm formId={form.hubspotFormId} />
									) : (
										<HubspotEmbedForm
											isInAsideContent={true}
											onFormSubmitted={() => setIsSubmitted(true)}
											formId={form.hubspotFormId}
											region={form.hubspotFormRegion}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				)}
			</Container>
			{background === BackgroundType.blue && (
				<div className={styles.background}>
					<Image
						lazyBoundary={lazyBoundary}
						src="/form_background.jpg"
						layout="fill"
						objectFit="cover"
						alt=""
					/>
				</div>
			)}
		</div>
	)
}
