import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { FunctionComponent, ReactNode } from 'react'
import { BackgroundType, ContentReferenceType } from '../../generated/content'
import type { ContentBlockResult } from '../data/content/ContentBlockFragment'
import { contentBlockReferenceNewsPerPage } from '../data/content/ContentBlockReferencesBaseFragment'
import type { ContentResult } from '../data/content/ContentFragment'
import type { LoginPageBlockResult } from '../data/content/LoginPageFragment'
import type { PricingPlansResult } from '../data/content/PricingPlansFragment'
import type { ResourceResult } from '../data/content/ResourceFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { useContentRendererCopyPasteBugWorkaround } from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { AppTimeline } from './AppTimeline'
import { ArticleCarouselCards } from './ArticleCarouselCards'
import { AsideContent } from './AsideContent'
import { Banner } from './Banner'
import { BannerFeatures } from './BannerFeatures'
import { BannerLogos } from './BannerLogos'
import { BannerTestimonials } from './BannerTestimonials'
import { BlogHero } from './BlogHero'
import { Box } from './Box'
import { CaseStudyCarouselCards } from './CaseStudyCarouselCards'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { CodeSnippet } from './CodeSnippet'
import { ComparasionTableBlock } from './ComparasionTable'
import { Container } from './Container'
import { ContemberButton } from './ContemberButton'
import { ContentMedia } from './ContentMedia'
import styles from './ContentRenderer.module.sass'
import { DemoRequestHero } from './DemoRequestHero'
import { EmbedTabs } from './EmbedTabs'
import { ExtensionGridCards } from './ExtensionGridCards'
import { FancyTitle } from './FancyTitle'
import { FeaturedBlogPosts } from './FeaturedBlogPosts'
import { FeaturedGrid } from './FeaturedGrid'
import { FeatureTiles } from './FeatureTiles'
import { Frame } from './Frame'
import { Gallery } from './Gallery'
import { GemmaDemoAnimation } from './GemmaDemoAnimation'
import { GemmaDemoAnimationVideo } from './GemmaDemoAnimationVideo'
import { GreenhouseEmbed } from './GreenhouseEmbed'
import { GridBoxList } from './GridBoxList'
import { GridCardList } from './GridCardList'
import { Hero } from './Hero'
import { HomePageHero } from './HomePageHero'
import { HubspotEmbedForm } from './HubspotEmbedForm'
import { Humblebrags } from './Humblebrags'
import { HumblebragsFour } from './HumblebragsFour'
import { HumblebragsThree } from './HumblebragsThree'
import { HumblebragsTwo } from './HumblebragsTwo'
import { Image } from './Image'
import { ImageCarousel } from './ImageCarousel'
import { ImageHoverReveal } from './ImageHoverReveal'
import { ImageWithCursors } from './ImageWithCursors'
import { InitiativesBlock } from './InitiativesBlock'
import { Jumbotron } from './Jumbotron'
import { JumbotronTwo } from './JumbotronTwo'
import { KeywordsTile } from './KeywordsTile'
import { LoginPageBlock } from './LoginPageBlock'
import { NewsBlock } from './NewsBlock'
import { OurValuePropsBlock } from './OurValueProps'
import { PricingBannerFeatures } from './PricingBannerFeatures'
import { PricingDiscoverCeros } from './PricingDiscoverCeros'
import { PricingFAQ } from './PricingFAQ'
import { PricingPlansBlock } from './PricingPlansBlock'
import { PricingProductListSection } from './PricingProductList'
import { ProductListSection } from './ProductListSection'
import { ReadingTime } from './ReadingTimeBlock'
import { RequestDemo } from './RequestDemo'
import { ResourceBox } from './ResourceBox'
import { ResourceGridCardList } from './ResourceGridCardList'
import { RevealableMediaContentList } from './RevealableMediaContentList'
import { StripWithLogos } from './StripWithLogos'
import { TableOfContents } from './TableOfContents'
import { TabsWithMedia } from './TabsWithMedia'
import { TabsWithRevealableMediaContent } from './TabsWithRevealableMediaContent'
import { useTheme } from './ThemeProvider'
import { TileSlider } from './TileSlider'
import { Timeline } from './Timeline'
import { Video } from './Video'
import { VisualLine } from './VisualLine'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	containerDisableGutters?: boolean
	contentWidthType: string
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'tableCell', 'tableRow', 'scrollTarget']
const referencesWithForcedWhiteBackground = ['news']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	featureTiles: function featureTiles({ reference }) {
		return (
			reference.featureTiles && (
				<Container>
					<FeatureTiles items={reference.featureTiles.items} />
				</Container>
			)
		)
	},
	stripWithLogos: function stripWithLogos({ reference }) {
		return (
			reference.logoList && (
				<Container>
					<StripWithLogos {...reference.logoList} title={reference.primaryText} />
				</Container>
			)
		)
	},
	banner: function banner({ reference }) {
		return (
			reference.banner?.type && (
				<Banner
					data={reference.banner}
					text={reference.primaryText}
					description={reference.secondaryText}
					background={reference.background}
					link={reference.link}
					form={reference.form}
					backgroundImage={reference.image}
					contentAlignment={reference.alignment}
					isWaitlist={reference.isFormWaitlist}
				/>
			)
		)
	},
	keywordsTile: function keywordsTile({ reference }) {
		return (
			reference.keywordsTile && (
				<KeywordsTile
					firstKeyword={reference.keywordsTile.firstKeyword}
					secondKeyword={reference.keywordsTile.secondKeyword}
					thirdKeyword={reference.keywordsTile.thirdKeyword}
					text={reference.keywordsTile.text}
					link={reference.keywordsTile.link}
				/>
			)
		)
	},
	hero: function hero({ reference }) {
		return reference.hero && <Hero {...reference.hero} />
	},
	box: function box({ reference }) {
		return (
			reference.primaryText &&
			reference.box && (
				<Box
					title={reference.primaryText}
					text={reference.secondaryText}
					link={reference.link}
					box={reference.box}
				/>
			)
		)
	},
	carouselWithProducts: function carouselWithProducts({ reference }) {
		return (
			reference.productList && (
				<ProductListSection title={reference.primaryText} items={reference.productList.items} />
			)
		)
	},
	asideContent: function asideContent({ reference }) {
		return (
			reference.asideContent && (
				<AsideContent
					background={reference.background}
					icon={reference.icons}
					title={reference.primaryText}
					description={reference.secondaryText}
					link={reference.link}
					mediumList={reference.media}
					contentAlignment={reference.alignment}
					form={reference.form}
					bgColor={reference.asideMediaColor}
					{...reference.asideContent}
				/>
			)
		)
	},
	jumbotron: function jumbotron({ reference }) {
		return (
			reference.primaryText && (
				<>
					<Jumbotron
						background={reference.background}
						primaryText={reference.primaryText}
						secondaryText={reference.secondaryText}
						link={reference.link}
						sublink={reference.sublink}
						icon={reference.icons?.icon}
						form={reference.form}
						video={reference.videoSelect}
						videoButtonLabel={reference.terciaryText}
						isWaitList={reference.isFormWaitlist}
						hideOnDesktop={reference.hideOnDesktop}
						hideOnMobile={reference.hideOnMobile}
						headingOne={reference.headingOne}
						headingTwo={reference.headingTwo}
						addPointingArrow={reference.addPointingArrow}
						hideDescOnDesktop={reference.hideDesriptionOnDesktop}
					/>
				</>
			)
		)
	},
	jumbotronTwo: function jumbotronTwo({ reference }) {
		return (
			reference.primaryText && (
				<>
					<JumbotronTwo
						background={reference.background}
						primaryText={reference.primaryText}
						secondaryText={reference.secondaryText}
						link={reference.link}
						sublink={reference.sublink}
						icon={reference.icons?.icon}
						form={reference.form}
						video={reference.videoSelect}
						videoButtonLabel={reference.terciaryText}
						isWaitList={reference.isFormWaitlist}
						hideOnDesktop={reference.hideOnDesktop}
						hideOnMobile={reference.hideOnMobile}
						headingOne={reference.headingOne}
						headingTwo={reference.headingTwo}
						addPointingArrow={reference.addPointingArrow}
						hideDescOnDesktop={reference.hideDesriptionOnDesktop}
					/>
				</>
			)
		)
	},
	image: function image({ reference }) {
		return (
			reference.image && (
				<Image
					image={reference.image}
					maxWidth={reference.primaryNumber}
					imageSource={reference.primaryText}
					rounded={reference.rounded}
				/>
			)
		)
	},
	news: function news({ reference }) {
		return (
			<NewsBlock
				title={reference.primaryText}
				paginatedNews={reference.paginateNews}
				itemsPerPage={contentBlockReferenceNewsPerPage}
				background={reference.background}
			/>
		)
	},
	newsroom: function newsroom({ reference }) {
		return (
			reference.newsroom && (
				<ArticleCarouselCards title={reference.primaryText} items={reference.newsroom.items} />
			)
		)
	},
	carouselWithCaseStudies: function carouselWithCaseStudies({ reference }) {
		return (
			reference.caseStudyList && (
				<CaseStudyCarouselCards
					title={reference.primaryText}
					items={reference.caseStudyList.items}
				/>
			)
		)
	},
	timeline: function timeline({ reference }) {
		return reference.textList && <Timeline timeline={reference.textList} />
	},
	video: function video({ reference }) {
		return (
			reference.videoSelect && (
				<Video
					{...reference.videoSelect}
					videoSource={reference.primaryText}
					rounded={reference.rounded}
				/>
			)
		)
	},
	bannerFeatures: function bannerFeatures({ reference }) {
		return (
			reference.bannerFeatures?.items && (
				<BannerFeatures
					title={reference.primaryText}
					subtitle={reference.secondaryText}
					items={reference.bannerFeatures}
					background={reference.background}
				/>
			)
		)
	},
	pricingBannerFeatures: function pricingBannerFeatures({ reference }) {
		return (
			reference.pricingBannerFeatures?.items && (
				<PricingBannerFeatures
					title={reference.primaryText}
					subtitle={reference.secondaryText}
					items={reference.pricingBannerFeatures}
					background={reference.background}
				/>
			)
		)
	},
	bannerLogos: function bannerLogos({ reference }) {
		return (
			reference.logoList && (
				<BannerLogos
					title={reference.primaryText}
					subtitle={reference.secondaryText}
					logoList={reference.logoList}
					background={reference.background}
				/>
			)
		)
	},
	bannerTestimonials: function bannerTestimonials({ reference }) {
		return (
			reference.bannerTestimonials?.items && (
				<BannerTestimonials items={reference.bannerTestimonials.items} />
			)
		)
	},
	gridBoxList: function gridBoxList({ reference }) {
		return reference.gridBoxList?.items && <GridBoxList items={reference.gridBoxList.items} />
	},
	gridCardList: function gridCardList({ reference }) {
		return (
			reference.gridCardList?.items && (
				<GridCardList
					items={reference.gridCardList.items}
					title={reference.gridCardList.title}
					type={reference.gridCardList.type}
					maxColumnsInRow={reference.gridCardList.maxColumnsInRow}
				/>
			)
		)
	},
	gallery: function gallery({ reference }) {
		return reference.media && <Gallery gallery={reference.media} />
	},
	appTimeline: function appTimeline({ reference }) {
		return reference.appTimeline && <AppTimeline apptimeline={reference.appTimeline} />
	},
	requestDemo: function requestDemo({ reference }) {
		return (
			reference.form && (
				<RequestDemo
					form={reference.form}
					title={reference.primaryText}
					background={reference.background}
				/>
			)
		)
	},
	resourceBox: function resourceBox({ reference }) {
		return reference.resourceBox && <ResourceBox {...reference.resourceBox} />
	},
	resourceList: function resourceList({ reference }) {
		return (
			reference.resources &&
			reference.resourceCategories && (
				<ResourceGridCardList
					items={reference.resources}
					categories={reference.resourceCategories}
				/>
			)
		)
	},
	frame: function frame({ reference }) {
		return (
			reference.frameUrl &&
			reference.primaryDoubleNumber && (
				<Frame
					frameUrl={reference.frameUrl}
					title={reference.primaryText}
					aspectRatio={reference.primaryDoubleNumber}
					aspectRatioMobile={reference.secondaryDoubleNumber ?? reference.primaryDoubleNumber}
				/>
			)
		)
	},
	embedHubspotForm: function embedHubspotForm({ reference }) {
		return (
			reference.form?.isHubspotFormEmbed &&
			reference.form.hubspotFormId && (
				<Container>
					<HubspotEmbedForm
						formId={reference.form.hubspotFormId}
						region={reference.form.hubspotFormRegion}
					/>
				</Container>
			)
		)
	},
	visualLine: function visualLine({ reference }) {
		return (
			reference.primaryText && (
				<VisualLine
					lineType={reference.visualLineType}
					title={reference.primaryText}
					text={reference.secondaryText}
					link={reference.link}
					images={reference.media}
				/>
			)
		)
	},
	extensionGridCardList: function extensionGridCardList({ reference }) {
		return reference.extensionList && <ExtensionGridCards items={reference.extensionList.items} />
	},
	codeSnippet: function codeSnippet({ reference }) {
		return (
			reference.primaryText && (
				<CodeSnippet text={reference.primaryText} programmingLanguage={reference.secondaryText} />
			)
		)
	},
	contentMedia: function contentMedia({ reference }) {
		return (
			reference.primaryText && (
				<Container>
					<ContentMedia
						title={reference.primaryText}
						text={reference.secondaryText}
						image={reference.image}
					/>
				</Container>
			)
		)
	},
	button: function button({ reference }) {
		// return (
		// 	<Container>
		// 		<WistiaPlayer videoId="m6itxtn372" wrapper="demmo-container-2" />
		// 	</Container>
		// )

		return (
			reference.link && (
				<Container>
					<ContemberButton
						{...reference.button}
						fill={undefined}
						size={undefined}
						type="link"
						href={contemberLinkToHref(reference.link)}>
						{reference.link.title}
					</ContemberButton>
				</Container>
			)
		)
	},
	revealableMediaContent: function revealableMediaContent({ reference }) {
		return (
			reference.revealableMediaContentList && (
				<Container>
					<RevealableMediaContentList items={reference.revealableMediaContentList.items} />
				</Container>
			)
		)
	},
	tabsWithRevealableMediaContent: function tabsWithRevealableMediaContent({ reference }) {
		return (
			reference.tabWithRevealableMediaContentList && (
				<Container>
					<TabsWithRevealableMediaContent
						items={reference.tabWithRevealableMediaContentList.items}
					/>
				</Container>
			)
		)
	},
	tabsWithMedia: function tabsWithMedia({ reference }) {
		return (
			reference.tabsWithMediaList && (
				<Container>
					<TabsWithMedia
						title={reference.primaryText}
						text={reference.secondaryText}
						items={reference.tabsWithMediaList.items}
						backgroundColor={reference.background}
					/>
				</Container>
			)
		)
	},
	genieDemoAnimation: function genieDemoAnimation() {
		return <GemmaDemoAnimationVideo />
	},
	gemmaDemoAnimation: function gemmaDemoAnimation() {
		return <GemmaDemoAnimation />
		// return <FeaturedGrid />
	},
	fancyTitle: function fancyTitle({ reference }) {
		return (
			<Container>
				<FancyTitle {...reference.fancyTitle} />
			</Container>
		)
	},
	comparasionTable: function comparasionTable({ reference }) {
		return <ComparasionTableBlock />
	},
	imageHoverReveal: function imageHoverReveal({ reference }) {
		return (
			reference.primaryText && (
				<>
					<ImageHoverReveal
						title={reference.primaryText}
						description={reference.secondaryText}
						images={reference.imageHoverRevealList?.items}
						link={reference.link}
					/>
				</>
			)
		)
	},
	ourValues: function ourValues({ reference }) {
		return <OurValuePropsBlock items={reference.ourValuesList?.items} />
	},
	humblebrags: function humblebrags() {
		return <Humblebrags />
	},
	humblebragsTwo: function humblebragsTwo() {
		return <HumblebragsTwo />
	},
	humblebragsThree: function humblebragsThree() {
		return <HumblebragsThree />
	},
	humblebragsFour: function humblebragsFour() {
		return <HumblebragsFour />
	},
	interactionBlock: function interactionBlock({ reference }) {
		return <FeaturedGrid items={reference.interactionBlockList?.items} />
	},
	imageWithCursors: function imageWithCursors({ reference }) {
		return (
			reference.image && (
				<ImageWithCursors image={reference.image} alt="" maxWidth={reference.primaryNumber} />
			)
		)
	},
	initiatives: function initiatives({ reference }) {
		return <InitiativesBlock items={reference.initiativesList?.items} />
	},
	loginPageBlock: function loginPageBlock({ reference }) {
		return <LoginPageBlock {...(reference.loginPageBlockList as LoginPageBlockResult)} />
	},
	pricingPlansBlock: function pricingPlansblock({ reference }) {
		return <PricingPlansBlock {...(reference.pricingPlansBlock as PricingPlansResult)} />
	},
	pricingDiscoverCeros: function pricingDiscoverCeros({ reference }) {
		return <PricingDiscoverCeros {...(reference as any)} />
	},
	pricingCarouselWithProducts: function pricingCarouselWithProducts({ reference }) {
		return (
			reference.productList && (
				<PricingProductListSection
					title={reference.primaryText}
					items={reference.productList.items}
				/>
			)
		)
	},
	fAQPricing: function fAQPricing({ reference }) {
		return (
			<PricingFAQ
				primaryText={reference.primaryText as string}
				{...(reference.fAQPricingList as any)}
			/>
		)
	},
	jumbotronBlog: function jumbotronBlog({ reference }) {
		return (
			<BlogHero
				primaryText={reference.primaryText}
				secondaryText={reference.secondaryText}
				primaryNumber={reference?.primaryNumber}
				image={reference.image}
				link={reference.link}
			/>
		)
	},
	readingTimeBlock: function readingTimeBlock({ reference }) {
		return (
			<ReadingTime primaryText={reference.primaryText} secondaryText={reference.secondaryText} />
		)
	},
	linkBlock: function linkBlock({ reference }) {
		return <TableOfContents linkList={reference.linkBlock as any} />
	},
	imageCarousel: function imageCarousel({ reference }) {
		return (
			reference.imageCarousel?.items && <ImageCarousel items={reference?.imageCarousel?.items} />
		)
	},
	featuredBlog: function featuredBlog({ reference }) {
		return (
			<FeaturedBlogPosts
				featuredBlogOne={reference?.featuredBlogOne as ResourceResult}
				featuredBlogTwo={reference?.featuredBlogTwo as ResourceResult}
			/>
		)
	},
	embedTabsBlock: function embedTabsBlock({ reference }) {
		return (
			<Container disableGutters>
				<EmbedTabs embedItems={reference.embedTabsBlock} />
			</Container>
		)
	},
	tileSlider: function tileSlider({ reference }) {
		return (
			<TileSlider link={reference.link} sublink={reference.sublink} tiles={reference.tileSlider} />
		)
	},
	homepageHero: function homePageHero({ reference }) {
		return (
			<Container>
				<HomePageHero
					title={reference.primaryText}
					description={reference.secondaryText}
					link={reference.link}
					sublink={reference.sublink}
					showRating={reference.showRating}
				/>
			</Container>
		)
	},
	demoRequestHero: function demoRequestHero({ reference }) {
		return (
			<DemoRequestHero
				title={reference.primaryText}
				form={reference.form as any}
				descTile={reference.descriptionTile}
				isWaitList={reference.isFormWaitlist}
			/>
		)
	},
	greenHouseEmbed: function greenHouseEmbed() {
		return <GreenhouseEmbed />
	},
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerDisableGutters = false,
	contentWidthType,
}) => {
	const { asPath } = useRouter()
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)
	const theme = useTheme()

	const isDemoReqPage = ['/demo-request/'].includes(asPath)

	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`is_theme_${theme.primary}`],
				styles[`is_site_${theme.site}`]
			)}>
			<CerosRichTextRenderer
				blocks={blocks}
				sourceField="json"
				renderElement={(element) => {
					const { type } = element.element

					if (type === 'table') {
						return (
							<div className={clsx(styles.section, styles[`is_reference_${type}`])}>
								<Container>{element.fallback}</Container>
							</div>
						)
					}

					if (nestedTypes.includes(type)) {
						return element.fallback
					}

					if (standaloneTypes.includes(type)) {
						const background = (element.reference as Block['reference']).background

						return (
							<div
								className={clsx(
									styles.section,
									contentWidthType && styles[`is_content_${contentWidthType}`],
									element.referenceType?.includes('jumbotronBlog') && styles['is_content_wide'],
									element.referenceType?.includes('readingTimeBlock') && styles['is_content_wide'],
									element.referenceType?.includes('readingTimeBlock') && styles['timeline'],
									element.referenceType?.includes('imageCarousel') && styles['is_images_carousel'],
									element.referenceType && styles[`is_reference_${element.referenceType}`],
									isDemoReqPage && styles.is_demo_req_page,
									styles[
										`is_background_${
											element.referenceType &&
											referencesWithForcedWhiteBackground.includes(element.referenceType)
												? BackgroundType.white
												: background
										}`
									]
								)}>
								{type !== 'reference' ||
								!element.referenceType ||
								element.referenceType in referenceRenderers ? (
									element.fallback
								) : (
									<Container>
										<div className={styles.notImplemented}>
											<div className={styles.notImplemented_name}>{element.referenceType}</div>
											is not yet implemented
										</div>
									</Container>
								)}
							</div>
						)
					}
					return (
						<div
							className={clsx(
								styles.section,
								styles.is_wysiwyg,
								contentWidthType && styles[`is_content_${contentWidthType}`],
								isDemoReqPage && styles.is_demo_req_page
							)}>
							<Container disableGutters={containerDisableGutters}>
								{/* <BreakableContent breakableFrom="768"> */}
								<Wysiwyg>{element.fallback}</Wysiwyg>
								{/* </BreakableContent> */}
							</Container>
						</div>
					)
				}}
				referenceRenderers={referenceRenderers}
			/>
		</div>
	)
}
