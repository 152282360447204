import Image from 'next/image'
import { LinkType } from '../../generated/content'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'

import styles from './HomePageHero.module.sass'

import { useWindowSize } from 'react-use'

import ratingsImg from '../images/rating.png'
import ratingsMobileImg from '../images/ratings-mobile.png'

export const HomePageHero = ({ title, description, link, sublink, showRating }: any) => {
	const { width } = useWindowSize()

	return (
		<div>
			{title && (
				<BreakableContent breakableFrom="768">
					<h1 style={{ position: 'relative' }} className={styles.title}>
						<CerosRichTextRenderer source={title} />
					</h1>
				</BreakableContent>
			)}

			{description && (
				<p style={{ maxWidth: '45.75rem' }} className={styles.text}>
					{description}
				</p>
			)}

			<div hidden={!link || !sublink || (!link && !sublink)} className={styles.btnWrapper}>
				{link && (
					<div hidden={width <= 600}>
						<Button
							type="link"
							href={contemberLinkToHref(link)}
							fill="blue"
							target={
								link.type !== LinkType.scrollToTarget && link.isTargetBlank ? '_blank' : undefined
							}>
							{link.title}
						</Button>
					</div>
				)}
				{sublink && (
					// <Button
					// 	outline={true}
					// 	type="link"
					// 	href={contemberLinkToHref(sublink)}
					// 	fill="transparent"
					// 	target={
					// 		sublink.type !== LinkType.scrollToTarget && sublink.isTargetBlank
					// 			? '_blank'
					// 			: undefined
					// 	}>
					// 	{sublink.title}
					// </Button>
					<Button
						type="link"
						href={contemberLinkToHref(sublink)}
						fill="blue"
						target={
							sublink.type !== LinkType.scrollToTarget && sublink.isTargetBlank
								? '_blank'
								: undefined
						}>
						{sublink.title}
					</Button>
				)}
			</div>

			{showRating && (
				<div className={styles.ratingsWrapper}>
					<Image
						src={width >= 600 ? ratingsImg : ratingsMobileImg}
						width={800}
						height={400}
						alt="t"
						style={{
							objectFit: 'contain',
							width: '100%',
							height: '100%',
							maxWidth: '400px',
						}}
					/>
				</div>
			)}
		</div>
	)
}
