import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { FormFragment } from './FormFragment'
import { ImageFragment } from './ImageFragment'

export const LoginPageFragment = () =>
	ZeusSelect<ValueTypes['LoginPageBlockList']>()({
		logo: [{}, ImageFragment()],
		title: true,
		image: [{}, ImageFragment()],
		backgroundColor: true,
		content: true,
		items: [
			{},
			{
				link: [
					{},
					{
						title: true,
						description: true,
						url: true,
						icon: [{}, { icon: [{}, ImageFragment()] }],
					},
				],
			},
		],
		form: [{}, FormFragment()],
	})

export type LoginPageBlockResult = MapType<
	ModelTypes['LoginPageBlockList'],
	ReturnType<typeof LoginPageFragment>
>
