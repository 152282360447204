import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { SetStateAction, useState, type FunctionComponent } from 'react'
import { ContentAlignment, FormAlignment, LinkType } from '../../generated/content'
import type { AsideContentResult } from '../data/content/AsideContentFragment'
import type { FormResult } from '../data/content/FormFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './AsideContent.module.sass'
import { AsideContentAccordion } from './AsideContentAccordion'
import { AsideContentMediumList, AsideContentMediumListProps } from './AsideContentMediumList'
import { Button } from './Button'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Form } from './Form'
import { HubspotEmbedForm } from './HubspotEmbedForm'
import { Icon } from './Icon'
import { ThankYouHome } from './ThankYouHome'
import { Wysiwyg } from './Wysiwyg'

export type AsideContentProps = {
	background?: string
	icon?: any
	title?: string
	description?: string
	link?: LinkResult
	mediumList?: AsideContentMediumListProps
	contentAlignment?: ContentAlignment
	form?: FormResult
	bgColor?: string | undefined
} & AsideContentResult

export const AsideContent: FunctionComponent<AsideContentProps> = ({
	background,
	icon,
	title,
	description,
	link,
	mediumList,
	contentAlignment = ContentAlignment.left,
	form,
	accordion,
	isAccordion,
	isAsideMediaOutOfLayout,
	isShapeInBackground,
	formAlignment,
	isFormBox,
	bgColor,
	descriptionTile,
}) => {
	const router = useRouter()
	const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false)

	const normalizePath = (path: string) => path.replace(/\/$/, '') // Remove trailing slash for comparison
	const isHomePage = normalizePath(router.asPath) === '' // Compare normalized path

	return (
		<div
			className={clsx(
				styles.wrapper,
				contentAlignment && styles[`view_${contentAlignment}`],
				isAsideMediaOutOfLayout && styles.is_asideMediaOutOfLayout
			)}>
			<Container size={isAsideMediaOutOfLayout ? 'wide' : 'normal'} disableGutters>
				<div
					className={clsx(styles.main, form && styles.hasForm, isAccordion && styles.is_accordion)}>
					<div
						className={clsx(
							styles.content,
							form && styles.hasForm,
							isAccordion && styles.is_accordion,
							background === 'grey' && styles.is_flexStart
						)}>
						{icon && (
							<div className={styles.iconWrapper}>
								<ContemberImage image={icon.icon} />
							</div>
						)}

						<div
							className={clsx({
								[styles.homePageWithForm]: isHomePage && form,
							})}>
							{title && (
								// <BreakableContent breakableFrom="768">
								<h2
									className={clsx(
										styles.title,
										isAccordion && styles.is_accordion,
										form && styles.hasForm,
										form && isHomePage && styles.fustatHere
									)}>
									<CerosRichTextRenderer source={title} />
								</h2>
								// </BreakableContent>
							)}
							{description && (
								<div className={styles.description}>
									<Wysiwyg source={description} />
								</div>
							)}
							{descriptionTile && (
								<div className={styles.tileWrapper}>
									{descriptionTile?.items.map((t) => {
										return (
											<div className={styles.tileContainer}>
												{t.icon?.icon && (
													<Image
														src={t.icon?.icon.url}
														alt={t.icon.icon.alt || (t.icon.icon.fileName as string)}
														key={t.icon.id}
														width={16}
														height={16}
														style={{ marginTop: '5px' }}
													/>
												)}
												{t.description && <CerosRichTextRenderer source={t.description} />}
											</div>
										)
									})}{' '}
								</div>
							)}
						</div>
						{link && (
							<Button
								type="link"
								href={contemberLinkToHref(link)}
								fill="blue"
								target={
									link.type !== LinkType.scrollToTarget && link.isTargetBlank ? '_blank' : undefined
								}>
								{link.title}
							</Button>
						)}
						{isAccordion && accordion?.items && (
							<div style={{ width: '100%' }} className={styles.accordion}>
								<AsideContentAccordion items={accordion.items} />
							</div>
						)}
					</div>
					<div
						style={{ backgroundColor: bgColor || 'transparent', overflow: 'hidden' }}
						className={clsx(
							styles.medias,
							form && styles.hasForm,
							isAccordion && styles.is_accordion
						)}>
						{isShapeInBackground && (
							<div className={styles.waveShape}>
								<Icon name="wave" />
							</div>
						)}
						{mediumList && <AsideContentMediumList {...mediumList} />}
						{/* <AsideContentMediumList {...mediumList} /> */}
						{form &&
							formAlignment === FormAlignment.nextToContent &&
							(isSubmittingForm && form ? (
								<ThankYouHome />
							) : (
								<AsideContentForm
									form={form}
									isFormBox={isFormBox}
									alignment={formAlignment}
									onForm={() => setIsSubmittingForm(true)}
								/>
							))}
					</div>
				</div>
			</Container>
		</div>
	)
}

type AsideContentFormProps = {
	form: FormResult
	isFormBox?: AsideContentProps['isFormBox']
	alignment?: FormAlignment
	onForm?: (value: SetStateAction<boolean>) => void
}

const AsideContentForm: FunctionComponent<AsideContentFormProps> = ({
	form,
	isFormBox,
	alignment,
	onForm,
}) => {
	return (
		<div
			className={clsx(
				styles.form,
				isFormBox && styles.is_box,
				form.isHubspotFormEmbed && styles.isInAsideContent,
				styles[`is_alignment_${alignment}`]
			)}>
			<>
				{form.hubspotFormId &&
					(form.isHubspotFormEmbed ? (
						<HubspotEmbedForm
							isInAsideContent={true}
							formId={form.hubspotFormId}
							region={form.hubspotFormRegion}
							onFormSubmitted={onForm as any}
						/>
					) : (
						<Form form={form} isFirstInputHighlighted />
					))}
			</>
		</div>
	)
}
