import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import { ContemberImage } from './ContemberImage'
import styles from './Image.module.sass'

export type ImageProps = {
	image: ImageResult
	maxWidth?: number | null
	imageSource?: string
	rounded: boolean | undefined
}

export const Image: FunctionComponent<ImageProps> = ({ image, maxWidth, imageSource, rounded }) => {
	const router = useRouter()
	const isJobs = router.asPath.startsWith('/jobs')

	return (
		<div
			style={
				maxWidth
					? {
							'--Image-imageWrapper-maxWidth': `${maxWidth}px`,
					  }
					: undefined
			}
			className={clsx(!isJobs && styles.imageWrapper)}>
			<div className={clsx(rounded && styles.roundedStyles)}>
				<ContemberImage
					image={image}
					layout="responsive"
					sizes={maxWidth ? `${maxWidth}px` : '(min-width: 75rem) 69rem, 100vw'}
				/>
			</div>
			{imageSource && (
				<div className={styles.sourceContainer}>
					<span className={styles.imageSource}>{imageSource}</span>
				</div>
			)}
		</div>
	)
}
